#dropdown-basic{
  border: none;
  outline: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 8px 16px;
  gap: 4px;
  width: 230px;
  height: 38px;
  background: #FFFFFF;
  border-radius: 100px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #808080;
}

.dropdown-toggle::after{
  color: black;
}

.dropdown-menu{
  width: 230px !important;
}