﻿.container {
  width: 100%;
}
.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 100% !important;
}
