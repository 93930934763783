@import "../../sass/variables.scss";

.book-img,
.book-placeholder {
  box-shadow: inset 0px 2.04846px 1.36564px rgba(255, 255, 255, 0.5),
    inset 0px -2.04846px 1.36564px rgba(0, 0, 0, 0.15);
  border: 1px solid #cccccc;
  border-radius: 8px;
}

.book-img {
  background: #ffffff;
}

.book-placeholder {
  align-items: flex-start;
  background: #4a4446;
  display: flex;
  justify-content: flex-start;
  padding: 16px;

  p {
    color: #fff;
    font-size: 20px;
    text-align: left;
  }
}

.books-sliders-list,
.books-grid-list {
  &[data-hubs="true"] {
    margin-left: 104px;
  }
}

.hub-grid-list {
  background-color: #f0f0f0;
  font-family: Poppins;
  height: 100%;
  margin-left: 104px;
  padding: 32px;
  width: calc(100% - 104px);

  &,
  .hub-grid,
  .book-cover-wrap,
  .book-title,
  .grid-item,
  .hub-icon-wrap {
    display: flex;
  }

  &,
  .hub-grid {
    align-items: flex-start;
    justify-self: flex-start;
  }

  &,
  .grid-item {
    flex-direction: column;
  }

  .book-title,
  .grid-item,
  .hub-icon-wrap {
    align-items: center;
  }

  .hub-icon,
  .hub-icon-wrap {
    height: 42px;
    margin-bottom: -21px;
    width: 42px;
    z-index: 10;
  }

  .book-cover-wrap,
  .book-cover-stack,
  .book-title {
    box-shadow: 0px -3px 2px 0px #00000026 inset,
      0px 3px 2px 0px #ffffff80 inset;
  }

  .book-cover-wrap,
  .book-cover-stack {
    border: 1px solid #cccccc;
    border-radius: 8px;
  }

  .hub-title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.03em;
    line-height: 36px;
    margin-bottom: 20px;
    text-align: left;
  }

  .hub-grid {
    flex-wrap: wrap;
    gap: 24px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .grid-item {
    background: none;
    border: none;
    cursor: pointer;
    height: 248px;
    justify-content: flex-start;
    margin: 0;
    outline: none;
    padding: 0;
    position: relative;
    width: 176px;
  }

  .hub-icon-wrap {
    background-color: #ffffff;
    border: 2px solid #333333;
    border-radius: 100%;
    justify-content: center;
  }

  .hub-icon-placeholder {
    height: 24px;
    width: 24px;
  }

  .book-cover-wrap {
    align-items: flex-end;
    height: 227px;
    justify-content: center;
    object-fit: fill;
    object-position: bottom;
    overflow: hidden;
    position: relative;
    width: 176px;
    z-index: 8;

    &[data-icon="false"] {
      margin-top: 21px;
    }

    &:hover {
      .reading-level {
        visibility: hidden;
      }
      .info-button {
        visibility: visible;
      }
    }
  }

  .book-cover {
    min-height: 227px;
    width: 176px;
  }

  .book-title {
    background-color: #ffffff;
    bottom: 0;
    min-height: 34px;
    justify-content: center;
    left: 0;
    padding: 5px 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 10;

    p {
      color: #333333;
      font-weight: 500;
      margin: 0;
    }
  }

  .book-cover-stack {
    background-color: #ffffff;
    height: 180px;
    position: absolute;

    &[data-stack="1"] {
      bottom: -6px;
      width: 168px;
      z-index: 6;
    }

    &[data-stack="2"] {
      bottom: -12px;
      width: 160px;
      z-index: 4;
    }
  }

  .reading-level {
    bottom: 4px;
    left: 4px;
    top: unset;
  }

  .info-button {
    background-color: transparent;
    border: none;
    bottom: 10px;
    outline: none;
    padding: 0;
    position: absolute;
    right: 10px;
    visibility: hidden;

    > .icon-wrap {
      border-radius: 50%;
      height: 40px;
      overflow: hidden;
      width: 40px;
    }

    .icon-img {
      border-radius: 50%;
      border: none;
      box-shadow: none;
      height: 40px;
      transition: transform 0s, border-color 0s;
      width: 40px;
    }
  }
}

.books-sliders-list {
  margin: 40px auto;
}
.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 9%;
}
.slider-heading {
  margin: 0;
  margin-left: 15px;
  padding-right: 0.7rem;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
}
.query {
  width: wrapped;
  height: 34px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: #333333;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-left: 0.3rem;
  padding-right: 1rem;
}
.count {
  width: 15px;
  height: 22px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #808080;
}
.left,
.right {
  display: flex;
}
.right i {
  margin-left: 1rem;
  font-size: 30px;
}

.book-list-view {
  display: flex;
  flex-direction: column;
  margin: 5% 0px;
}

.book-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  width: 100%;
  height: 168px;
  background: #f5f5f5;
  border-radius: 24px;
  margin: 12px 0;
  cursor: pointer;
  @media (max-width: 1024px) {
    width: 115%;
    margin: 12px -60px !important;
  }
}
.book-list-image {
  width: 120px;
  height: 120px;
}
.book-list-image {
  .book-placeholder,
  img {
    width: 120px;
    height: 120px;
  }
}
.book-list-details {
  width: 338px;
  height: 36px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #333333;
  margin-left: 20px;
  .book-list-title {
    width: 338px;
    height: 36px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: #333333;
  }
  .book-list-author {
    width: 375px;
    height: 24px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #808080;
  }
  .book-reading-level {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;
    gap: 8px;
    width: 168px;
    height: 26px;
    background: #808080;
    background-blend-mode: multiply;
    border-radius: 4px;
    .reading-book-level-text {
      width: 100px;
      height: 18px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
    }
  }
}
.book-details {
  display: flex;
  gap: 8px;
}
.book-list-actions {
  display: flex;
  gap: 8px;
}
.purple-button {
  margin: 0 4px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(204, 204, 204, 0.5) 100%
    ),
    #bb26d9;
  background-blend-mode: multiply, normal;
  padding: 0;
  .image {
    width: 24px;
    height: 24px;
    margin: 7px;
  }
}
.green-button {
  margin: 0 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 0px;
  width: 82px;
  height: 40px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(204, 204, 204, 0.5) 100%
    ),
    #1fad4e;
  background-blend-mode: multiply, normal;
  border-radius: 500px;
  flex: none;
  .image {
    width: 24px;
    height: 24px;
  }
  .text {
    width: 32px;
    height: 18px;
    padding-left: 5px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}
.sky-blue-button {
  margin: 0 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 0px;
  width: 112px;
  height: 40px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(204, 204, 204, 0.5) 100%
    ),
    #3db8f5;
  background-blend-mode: multiply, normal;
  border-radius: 500px;
  .image {
    width: 24px;
    height: 24px;
  }
  .text {
    width: 62px;
    height: 18px;
    padding-left: 5px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}
.gray-button {
  margin: 0 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 10px;
  width: 86px;
  height: 40px;
  background: #b3b3b3;
  border-radius: 500px;
  .image {
    width: 24px;
    height: 24px;
  }
  .text {
    width: 36px;
    height: 18px;
    padding-right: 5px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}
.book-reading-level {
  background: rgba(74, 68, 70, 0.9);
  color: white;
  width: 180px;
  border-radius: 4px;
  padding: 5px;
}

.pull-right.users-name {
  color: black;
  font-family: $mainFont;
  line-height: 3.8;
  font-weight: 300;
}
.downloaded-book {
  position: absolute;
  right: 5px;
  top: 5px;
}

.books-row {
  padding-bottom: 50px;
  margin-top: 50px;
  margin-left: 40px;
}
.last-element {
  height: 20px;
  width: 100%;
}
.toolbar .toolbar-page-numbers {
  font-family: $mainFont;
  font-size: 16px;
  margin: 0px;
  padding: 10px 10px;
}

.book-bag-content {
  background: #dddddd;
  min-height: 100vh;
  padding: 0px;
  box-shadow: 0px 2.5px 10px rgba(8, 8, 8, 0.91);
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  .search-input {
    padding: 35px 0px 0px 0px;
    margin-bottom: 0px;
    margin-left: 7px;
    .form-group .form-control {
      color: black;
      height: 45px;
    }
  }
  .bookbag-search-form {
    height: 52px;
    margin-bottom: 35px;
  }

  .bookbag-search-inputs {
    button {
      width: 100%;
      height: 45px;
      line-height: 1;
      background-color: $blue;
      border-bottom: 2.5px solid #1a6bb1;
    }
    .col-md-5 {
      padding-left: 20px;
    }
  }
  .bookbag-searchmode {
    figure {
      margin: 0px 3.5px;
      display: inline-block;
      &:active img {
        border: 3px solid $darkBlue;
        border-radius: 15.5px;
      }
      img {
        max-height: 52px;
        margin: 0px 2.5px 0px 2.5px;
        &.backpack,
        &.bookshelf {
          border: 3px solid #1697fe;
          border-radius: 15px;
        }
      }
      figcaption {
        color: #777;
        font-size: 12px;
        padding-top: 10px;
        margin-bottom: 15px;
        font-weight: 700;
        text-align: center;
      }
    }
  }

  .bookbag-search-images {
    img {
      max-height: 55px;
      margin: 0px 3.5px 0px 3.5px;
      text-align: center;
      &.backpack,
      &.all-tags,
      &.bookshelf,
      &.social-science-bc,
      &.math-bc,
      &.science-bc,
      &.all-bc,
      &.fiction-bc,
      &.non-fiction-bc,
      &.language-bc {
        border: 3px solid #1697fe;
        border-radius: 15.5px;
      }
    }
    figcaption {
      font-size: 12px;
      color: #777;
      padding-top: 10px;
      text-align: center;
    }
    figure {
      display: inline-block;
      &:active img {
        border: 3px solid $darkBlue;
        border-radius: 15.5px;
      }
    }
    .advanced-search-buttons {
      padding: 0;
      text-align: right;
      .advanced-search,
      .advanced-search-active {
        text-align: right;
      }
      button.advanced-search,
      button.advanced-search-active,
      button.clear-advanced-search {
        line-height: 0.7;
      }
      .advanced-search,
      .clear-advanced-search {
        color: $grayBlue;
        &:active {
          color: $darkBlue;
        }
      }
      .advanced-search-active {
        color: red;
        text-decoration: underline;
      }
    }
  }
  .btn-blue {
    background-color: $blue;
    border-bottom: 2.5px solid #1a6bb1;
    color: white;
  }
  .btn-white {
    background-color: white;
    border-bottom: 2.5px solid rgba(8, 8, 8, 0.51);
    color: $blue;
  }
  .raise-book {
    // position: relative;
    transition: transform 0.5s;
    transition-timing-function: cubuc-bezier(0.5, 0.31, 0.84, 0.05);
    transform: translateY(-100px);
    // top: -150px;
    z-index: 10;
    // .book-cover{
    //     display: none;
    // }
  }
  .glow {
    filter: drop-shadow(0px 1px 10px rgba(37, 153, 254, 0.65));
  }
  .book-shadow {
    filter: drop-shadow(3px 2.5px 3px rgba(8, 8, 8, 0.51));
  }
  .book-container {
    margin-top: 50px;
    margin-bottom: 25px;
    padding-left: 12px;
    transition: transform 0.5s;
    transition-timing-function: cubuc-bezier(0.5, 0.31, 0.84, 0.05);
  }

  .book {
    border-radius: 30px;
    height: 291px;
    width: 228px;
    margin: 0 auto;
    p.book-title {
      position: relative;
      top: 130px;
      font-family: $mainFont;
      margin: 0px 25px;
      min-height: 0px;
      max-height: 0px;
    }
    .book-details {
      position: absolute;
      margin-left: 19px;
      height: 256px;
      width: 207px;
      z-index: 10;
      color: #fff;
      cursor: pointer;
      user-select: none; // this prevents a double click from initiating a text selection
      p.book-title {
        position: relative;
        top: 130px;
        font-family: $mainFont;
        margin: 0px 25px;
        min-height: 20px;
        max-height: 20px;
      }
      .book-reading-level {
        position: absolute;
        bottom: 10px;
        width: 100%;
        padding: 7.5px;
        background: rgba(74, 68, 70, 0.9);
        font-family: $mainFont;
        text-align: center;
        font-weight: 100;
        .grl-hide,
        .atos-hide,
        .lex-hide {
          visibility: hidden;
        }
      }
      .external-link {
        position: relative;
        top: 10px;
        left: 10px;
        width: 30px;
        height: 30px;
        line-height: 1;
        border-radius: 50%;
        i {
          top: 22%;
          position: relative;
          left: 5%;
        }
      }
    }
    .book-button,
    .add-book-button,
    .download-book-button {
      width: 100%;
      margin-top: 10px;
      padding: 6px 0px;
      font-family: $mainFont;
    }
  }
  .visible {
    visibility: visible;
  }
  .hidden {
    visibility: hidden;
  }
  .offline-message,
  .empty-search-message {
    text-align: center;
    font-size: 25px;
    padding: 0;
    color: #555;
    font-weight: 500;
  }
}

// .advanced-search-modal{
p {
  color: #777;
}
.tag span {
  padding: 10px;
  margin: 15px;
  background-color: #777;
}
// }
.static-modal-update-user-profile {
  padding-top: 64px;
  background-color: rgba(0, 0, 0, 0.3);
  .column-size {
    padding: 0px 25px;
  }
  .modal-dialog {
    width: 42%;
  }
  .modal-title {
    font-family: $headerFont;
    font-weight: 300;
    font-size: 21px;
    padding-top: 10px;
    color: black;
  }
  .modal-content {
    background-color: #dddddd;
    padding: 10px;
  }
  .modal-header,
  .modal-body {
    border-bottom: none;
    .form-group {
      input {
        color: #555;
        font-size: 22px;
        font-weight: 400;
        border-bottom: 2.8px solid #cbcbcb;
        &::placeholder {
          color: #555;
          font-weight: 400;
        }
      }
      .form-control {
        height: 52px;
      }
    }
  }
  .password-text {
    padding: 10px 0 10px 1px;
    color: black;
    font-size: 12px;
    text-align: left;
    .first-row {
      padding-bottom: 5px;
    }
  }
}

.navbar-nav .main-header-item a {
  line-height: 32px;
  &:focus {
    outline: none;
  }
}
.navbar-nav .main-header-item {
  &:active {
    background-color: $backgroundGrey;
  }
}
.navbar-nav > .main-header-item.disabled > a {
  color: #aaa;
}

.book-bag-header .navbar-collapse {
  padding-right: 30px;
}
.header-logo img {
  height: 60px;
  padding: 5px;
}

.book-info-modal {
  font-family: "Poppins";

  button {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 0;
    outline: none;
    white-space: nowrap;
  }

  p {
    font-weight: 600;
  }

  input {
    outline: none !important;
  }

  .modal-dialog,
  .modal-content {
    height: 100%;
    max-height: 480px;
    max-width: 920px;
    width: 100%;
  }

  .modal-content {
    background-color: #fff;
    border-radius: 16px;
    padding: 32px;

    .modal-body,
    .modal-footer {
      padding: 0px;
    }

    .modal-body {
      position: relative;

      &,
      .content-wrap,
      .tabs-list,
      .tag-list {
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
      }

      .content-wrap {
        flex-direction: column;
        margin-left: 24px;
        width: 100%;
      }

      .book-placeholder,
      img.book-img {
        height: 224px;
        transition: transform 0.2s, border-color 0.2s;
        width: 176px;
      }

      .book-placeholder {
        p {
          font-size: 18px;
          font-weight: 600;
          margin-top: 8px;
        }
      }

      .tabs-list {
        list-style: none;
        margin: 0 0 32px;
        padding: 0;

        li {
          margin-right: 20px;
        }

        button {
          border-bottom: 2px solid transparent;
          border-radius: 0;
          color: #333333;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          padding: 5px 0;
          transition: border-color 300ms ease-in-out, color 300ms ease-in-out;

          &[data-active="true"] {
            border-bottom-color: #fcb823;
          }

          &[data-active="false"] {
            color: #b3b3b3;
          }

          &:focus,
          &:hover {
            color: #333333;
          }
        }
      }

      .body,
      .body-inner,
      .input-group {
        width: 100% !important;
      }

      .body {
        .body-inner {
          display: none;

          &[data-active="true"] {
            display: block;
          }
        }

        .title,
        .ibsn {
          margin-bottom: 4px;
        }

        .description,
        .meta {
          color: #808080;
          font-size: 16px;
          line-height: 22.5px;
        }

        .description,
        .ibsn {
          font-weight: 400;
        }

        .title {
          color: #333333;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
        }

        .ibsn {
          color: #3db8f5;
          font-size: 14px;
          line-height: 21px;
        }

        .description {
          margin-bottom: 16px;
        }

        .meta {
          font-weight: 500;
        }
      }

      .search {
        width: 20rem;
      }

      .search-input-field {
        border-radius: 0 2rem 2rem 0;
        border-color: #808080;
        border-left: none !important;
        border-width: 1px;
        box-shadow: none !important;
        font-size: 0.9rem !important;
        font-style: normal;
        font-weight: 500;
        height: 2.5rem !important;
        line-height: 21px;
        margin: 0 !important;
        outline: none !important;
        padding: 0.5938rem 1rem 0.5938rem 0.25rem;
      }

      .input-icon {
        background-color: white;
        border-bottom-left-radius: 2rem;
        border-color: #808080;
        border-right: none;
        border-top-left-radius: 2rem;
        border-width: 1px;
        height: 2.5rem !important;
        margin-right: -14px;
        padding: 0.5rem 1rem;
      }

      .resource-list-wrap {
        height: 14.0625rem;
        overflow-y: auto;
      }

      .resource-list,
      .tag-list {
        align-items: flex-start;
        height: auto;
        list-style: none;
        margin: 1rem 0 0;
        padding: 0;

        &,
        li {
          display: flex;
          justify-items: flex-start;
        }

        a,
        p {
          font-size: 14px;
          line-height: 21px;
        }

        p {
          margin: 0;
        }
      }

      .resource-list {
        flex-direction: column;

        &,
        li,
        a,
        p {
          width: 100%;
        }

        a,
        p {
          font-weight: 500;
        }

        li {
          align-items: center;
          height: 2.8125rem;
          margin: 0;
          padding: 0.75rem 1rem;

          &:nth-child(odd) {
            background-color: #f5f5f5;
          }
        }

        a {
          color: #3db8f5;
          max-width: 12.5rem;
          text-decoration: none !important;
        }
      }

      .tag-list {
        flex-wrap: wrap;
        gap: 0.5rem;

        li {
          background-color: #d9d9d9;
          border-radius: 4px;
          height: 1.8125rem;
          padding: 0.25rem 0.5rem;

          p {
            color: #333333;
            font-weight: 600;
          }
        }
      }
    }

    .modal-footer {
      margin-top: 1.875rem;

      button {
        border-radius: 500px;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        height: 40px;

        img {
          height: 24px;
          margin-right: 8px;
          width: auto;
        }

        &.student-link,
        &.open-book {
          margin-right: 12px;
          padding: 8px 16px 8px 12px;
        }

        &.student-link {
          background-color: #3db8f5;
        }

        &.open-book {
          background-color: #1fad4e;
        }

        &.book-bag {
          background-color: #bb26d9;
          padding: 8px;
          width: 40px;

          img {
            margin: 0;
          }
        }
      }
    }

    .close-modal {
      height: 24px;
      padding: 6px;
      position: absolute;
      right: 16px;
      top: 16px;
      width: 24px;

      img {
        height: 16px;
        width: 16px;
      }
    }
  }
}
