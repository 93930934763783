.google-login {
  background-image: url("../../images/google-login.png");
  background-size: 180px 40px;
  background-repeat: no-repeat;
  width: 180px;
  height: 40px;
  border: none !important;
  background-color: transparent;
  box-shadow: none !important;
  border-radius: 20px !important;
  div {
    display: none;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  align-items: flex-start;
  padding: 48px 160px 40px 80px;
  flex: 1;
  height: 100vh;
  width: 50vw;
  background: #ffffff;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.main-page {
  flex: 1;
  height: "100vh";
  @media (max-width: 1024px) {
    display: none;
  }
}

.title {
  display: "flex";
  flex-direction: "row";
  align-items: "flex-start";
  padding: "0px 0px 4px";
  gap: "8px";
  width: "480px";
}

.input-container {
  margin-top: "20px";
  display: "flex";
  flex-direction: "column";
  align-items: "flex-start";
  padding: "0px";
  gap: "20px";
  width: "480px";
  height: "392px";
  margin: 20px;
  flex: "none";
  align-self: "stretch";
  flex-grow: 0;
}
.school-container {
  display: "flex";
  flex-direction: "column";
  align-items: "flex-start";
  align-self: "stretch";
  flex-grow: 0;
}
.school-text {
  margin-bottom: 8px;
  color: "#808080";
}

.field-container {
  flex-direction: "column";
  align-items: "flex-start";
  padding: "0px";
  gap: "4px";
  width: "480px";
  height: "65px";
  flex: "none";
  align-self: "stretch";
  flex-grow: 0;
  margin-top: "-2px";
}
.field {
  width: "480px";
  height: "21px";
  font-weight: 500;
  font-size: "14px";
  line-height: "21px";
  color: "#808080";
}
.dibs-form {
  margin-top: 20px;
}

.content {
  background: #f0f0f0;
  min-height: 100vh;
  padding: 0px;
  .login-page {
    #login-form {
      h2 {
        color: black;
        font-weight: 300;
        margin-top: 60px;
        margin-bottom: 20px;
        margin-left: 2.3px;
      }
      .schools-autocomplete-text {
        color: black;
        font-weight: 300;
      }
    }
    button#btn-login {
      float: right;
    }
    input {
      border-bottom: 2px solid #cbcbcb;
    }
    .forgot-password {
      padding-left: 0px;
      a {
        color: #4a8bf5;
      }
    }
    .autocomplete-container {
      z-index: 5;
      .form-control-feedback.glyphicon {
        right: 40px;
        top: 28px;
      }
      .school-select__single-value {
        max-width: calc(100% - 15px);
      }
      .school-select__control {
        border-bottom: 2.8px solid #cbcbcb;
      }
      .school-select__value-container {
        padding: 8px 15px;
      }
      .school-select__menu-list {
        color: black;
      }
    }
  }
  .userSchoolClassName {
    width: 100px;
  }

  @media (max-width: 502px) {
    .login-page button#btn-login {
      top: 202px;
    }
  }
}

.signin-button {
  @media (max-width: 760px) {
    flex-direction: column !important;
    margin-left: 8px !important;
  }
}

.login-btn {
  @media (max-width: 760px) {
    width: 178px !important;
    margin-top: 15px;
  }
}

.custom-school-select {
  width: 480px !important;
  @media (max-width: 760px) {
    width: 100% !important;
  }
}
