@import "../../sass/variables.scss";

.gridBook {
  width: 176px !important;
  height: 227px !important;
  position: relative;

  button {
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
  }

  .book-placeholder,
  img.book-img {
    height: 224px;
    left: 0px;
    position: absolute;
    top: 10px;
    transition: transform 0.2s, border-color 0.2s;
    width: 176px;
  }

  p {
    font-size: 18px;
    font-weight: bold;
    margin-top: 8px;
  }

  .bottom-right-button,
  .bottom-left-button {
    > .icon-wrap {
      border-radius: 50%;
      height: 40px;
      overflow: hidden;
      position: absolute;
      top: 186px;
      width: 40px;
    }
  }

  .bottom-right-button {
    > .icon-wrap {
      left: 125px;
    }

    .icon-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: none;
      box-shadow: none;
      transition: transform 0s, border-color 0s;
    }
  }

  .bottom-left-button {
    > .icon-wrap {
      left: 10px;
      &::after {
        content: "";
        height: 40px;
        left: 0;
        position: absolute;
        top: 0;
        width: 40px;
      }
    }

    .icon-img {
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: transparent;
      box-shadow: none;
      transition: transform 0s, border-color 0s;
      position: relative;
      z-index: 20;
    }
  }

  .top-right-button {
    left: 125px;
    position: absolute;
    top: 18px;

    .icon-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 5px;
      background: #bb26d9;
      border: none;
      box-shadow: none;
      transition: transform 0s, border-color 0s;
    }
  }

  i {
    font-size: 24px;
    padding-left: 5px;
    padding-top: 3px;
  }

  .bottom-right-button,
  .bottom-left-button,
  .top-right-button {
    visibility: hidden;
  }

  &:hover {
    .bottom-right-button,
    .bottom-left-button,
    .top-right-button {
      visibility: visible;
    }
  }

  &:hover {
    img.book-img {
      transform: scale(1.05, 1.05);
      border-color: #ffffff;
    }
    .reading-level {
      visibility: hidden;
    }
  }
}

.reading-level {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;
  position: absolute;
  width: 168px;
  height: 26px;
  left: 4px;
  top: 204px;
  background: #0000008a;
  background-blend-mode: multiply;
  border-radius: 4px;

  .reading-text {
    width: 100px;
    height: 18px;
    text-align: center;
    margin: auto;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
  }
}
