html,
body,
#root {
  height: 50%;
  margin: 0px;
}

.example-container {
  height: 100%;
  background-color: rgb(185, 209, 253);
}

.panel-container {
  width: 100%;
  height: "100vh";
  display: flex;
  justify-content: end;
}
.additional-class {
  background-color: white;
  height: 70% !important;
  width: 24vw !important;
  border-radius: 14px;
}
.sliding-container {
  display: flex;
  justify-content: center;
  width: 28vw !important;
  padding-top: 1rem !important;
}

button {
  margin: 2px;
}

.panel-content {
  width: 50%;
  height: 20vw;
}

.form-container {
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.input-container {
  padding: 10px;
}

.close-button {
  position: relative;
  top: -4px;
  right: 0px;
  font-size: 28px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: #b3b3b3;
  max-height: 2.6rem;
}

.glass {
  width: 100vw !important;
}

.drawer-item {
  margin-bottom: 4px;
  border: 1px solid #d9d9d9 !important;
  padding: 4px 6px !important;
  background-color: #f2f0f0 !important;
  font-size: 16px;
  margin: 8%;
  margin-top: 0;
}

.drawer-item-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin: 0;
}

.localParagraph {
  margin-left: 8%;
  margin-top: 4%;
  margin-bottom: 2%;
  width: 61px;
  height: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #808080;
}

.model-container {
  display: flex !important;
  flex-direction: row;
  margin-left: 8%;
}
.model-container .active {
  border-bottom: 2px solid rgb(255, 187, 0);
  font-weight: bold !important;
  color: black;
}

.modelHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: #b3b3b3;
  margin-right: 6%;
}

/* Media Queries for responsiveness */
@media screen and (min-width: 1350px) {
  .additional-class {
    width: 20vw !important;
    margin-left: -15%;
  }
}

@media screen and (min-width: 950px) and (max-width: 1150px) {
  .additional-class {
    width: 26vw !important;
  }
}
