@import "../../sass/variables.scss";

.navbar {
  padding: 8px 16px 16px 20px !important;

  .search-wrap {
    margin-right: auto;
  }

  #search-form {
    button#run-search {
      background-color: #1fad4e;
      border: none;
      border-radius: 100%;
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      height: 38px;
      line-height: 1;
      margin-left: 8px;
      padding: 8px;
      text-transform: uppercase;
      width: 38px;
    }
  }

  .search {
    width: 20rem;
  }

  .search-small {
    width: 8rem;
  }

  .reading {
    width: 11rem;
  }

  .search-bg {
    background-color: #f0efefb0;
    border-radius: 3rem;
    align-items: center;
    padding: 0.8rem;
  }

  .reading-level-field {
    border-color: #808080;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-left: none;
    height: 2.5rem !important;
    margin: 0 !important;
    font-size: 0.9rem !important;
    border-width: 2px;
  }

  .search-input-field {
    border-color: #808080;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-left: none;
    height: 2.5rem !important;
    margin: 0 !important;
    font-size: 0.9rem !important;
    border-width: 2px;
  }

  .full-width {
    border-top-left-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
    border-left: 1.5px solid !important;
  }

  .input-icon {
    border-color: #808080;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    height: 2.5rem !important;
    background-color: white;
    margin-right: -14px;
    border-right: none;
    border-width: 2px;
  }

  .search-input-small {
    border-color: #808080;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-left: none;
    height: 2.5rem !important;
    margin: 0 !important;
    font-size: 0.9rem !important;
    border-width: 2px;
  }

  input:focus {
    outline: none !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-color: #808080 !important;
  }

  .navigation-element {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-blend-mode: multiply, normal;
    border-radius: 500px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #ffffff;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .navigation-element:hover {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(204, 204, 204, 0.5)
    );
    background-blend-mode: multiply, normal;
  }
}

.nav-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  outline: inherit;

  img {
    height: 32px;
    width: 32px;
  }
}

.username {
  margin: 0;
  padding-top: 10px !important;
  padding-right: 0px !important;
}

.nav-logo {
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
}

.small-nav {
  height: 4rem !important;
  width: 100%;
  position: fixed !important;
  top: 0;
  z-index: 1000 !important;
  margin: auto;

  .nav-logo {
    margin-left: 0.5rem;

    img {
      width: 7rem;
      height: auto;
    }
  }

  .swipper-li {
    width: 90%;
    margin-left: 1rem;
    max-width: 77rem;
    @media (max-width: 1250px) {
      max-width: 42rem;
    }
  }
}

.big-nav {
  .nav-logo img {
    width: auto;
    height: 3.75rem;
  }

  .swipper-li {
    max-width: 77rem;
    @media (max-width: 1250px) {
      max-width: 41rem;
    }
  }
}

.me-2 {
  .add-icon {
    color: rgba(184, 180, 180, 0.562);
  }
}

.nav-div {
  .pre-button {
    position: absolute;
    top: -4px;
    left: 0;
    z-index: 999;
    background-color: #f8f9fa;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
    box-shadow: 15px 0px 18px white;
    padding: 0.7rem;
    @media (max-width: 1250px) {
      left: -5px;
    }
  }
  .next-button {
    position: absolute;
    top: -4px;
    right: -1px;
    z-index: 999;
    border-bottom-left-radius: 999px;
    border-top-left-radius: 999px;
    background-color: #f8f9fa;
    box-shadow: -14px 4px 18px white;
    transform: translateX(0px);
    padding: 0.7rem;
    @media (max-width: 1250px) {
      right: -5px;
    }
  }
  .hide-button {
    display: none;
    z-index: 100;
  }
}

.swiper .res-slider {
  max-width: max-content;
}

.swiper-wrapper {
  justify-content: center;
}

.col {
  .display-text {
    font-weight: bold;
    font-size: 0.8rem;
    color: rgb(146, 146, 146);
  }
}

.nav-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

#big-navbar-wrap {
  padding: 16px 0 0;
}

#big-navbar-nav {
  flex-wrap: wrap;
  gap: 6.5px;
  margin: 0;
  max-width: 1520px;
  overflow: hidden;
  padding: 0;
  transition: height 0.3s ease-in-out;

  &[data-open="false"] {
    height: 40px;
  }
}

.navigation-toggle {
  flex-direction: row-reverse !important;
  margin: 0 0 auto 6.5px !important;
  padding: 0 20px !important;

  &[data-active="true"] {
    img {
      transform: scale(1, -1);
    }
  }

  .MuiChip-label {
    padding: 0;
  }

  .MuiChip-icon {
    margin: 0 0 0 8px;
  }
}

#side-nav {
  align-items: center;
  background-color: #d8d8d8;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 100%;
  justify-content: flex-start;
  padding: 4rem 2rem 2rem;
  position: fixed;
  top: 4rem;
  width: 104px;

  .side-nav-btn,
  .side-nav-guide {
    &[data-active="false"] {
      display: none;
    }
  }

  .side-nav-btn {
    border: none;
    margin: 0;
    outline: none;
    padding: 0;

    &[data-type="hub-main"] {
      background-color: #1fad4e;
    }

    &[data-type="hub-main"],
    &[data-type="hub-sub"][data-icon="false"],
    &[data-type="hub-children"][data-icon="false"] {
      border-radius: 100%;
      overflow: hidden;
    }

    &[data-type="hub-sub"][data-icon="false"] {
      background-color: #3db8f5;
    }

    &[data-type="hub-children"][data-icon="false"] {
      background-color: #bb26d9;
    }

    &[data-type="hub-sub"][data-icon="true"],
    &[data-type="hub-children"][data-icon="true"] {
      background-color: transparent;
    }

    img {
      height: 28px;
      width: 28px;
    }

    &,
    &[data-type="hub-children"][data-icon="true"] img,
    &[data-type="hub-sub"] img {
      height: 48px;
      width: 48px;
    }
  }

  .btn-name {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
}
