﻿$headerFont: "Raleway", sans-serif;
$mainFont: "Roboto", sans-serif;
$blue: #2699fe;
$green: #40ac4d;
$darkGreen: #3fac4c;
$darkBlue: #192854;
$grayBlue: #7094ad;
$grayBlueShadow: #3b5161;
$darkGray: #444444;
$darkerGray: #333333;
$yellow: #fab922;
$brightYellow: #fcb73a;
$darkyellow: #fdb928;
$darkerYellow: #b17902;
$buttonyellow: #fcb924;
$yellowShadow: #918127;
$orange: #ff8f00;
$backgroundWhite: #eceff4;
$backgroundGrey: #bec8da;
$background: $darkGray;
$lightGray: #f5f5f5;
$toolbarBg: $lightGray;
$red: #d00000;
$shadow: #454646;
$textColor: white; //$blue;
$groupNotes: #4343dd;
$classNotes: #e28540;
$teacherStudentNotes: #b541e0;
$studentNotes: #d25651;
$darkOrange: #e87046;
$hlteal: #09d8f8;
$hlyellow: #ffea01;
$hlgreen: #63e60d;
$hlorange: #ff8f02;
$markerBlue: #2a3db9;
