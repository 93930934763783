.no-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  outline: inherit;
}
.right-options {
  margin-right: 3rem;
  @media (max-width: 1024px) {
    margin-right: -3rem;
  }
}

.header-actions {
  @media (max-width: 1024px) {
    margin-left: 2rem;
  }
}

.section-image {
  width: 1.6rem;
  height: 1.6rem;
  object-fit: cover;
  border-radius: 50%;
}
