@import "../../sass/variables.scss";

.hide-nav {
  display: none;
}
.view-more {
  @media (max-width: 1024px) {
    margin-left: 0rem !important;
    margin-top: 10px !important;
  }
}
.slider-header {
  display: flex;
  margin-right: 110px;
  margin-left: 150px;
  @media (max-width: 1024px) {
    margin: 0px 45px;
  }
  justify-content: space-between;
  align-items: center;
  .slider-custom-nav {
    .owl-prev-right,
    .owl-prev-left {
      border: none;
      border-radius: 50%;
      cursor: pointer;
      font-size: 24px;
      margin: 4px;
      padding: 6px;
      margin-top: 6px;
      background: #d6d6d6;
    }
  }

  .section-image {
    width: 1.6rem;
    height: 1.6rem;
    object-fit: cover;
    border-radius: 50%;
  }
}
.category-btn {
  background: none;
  border: none;
  margin: 0;
  outline: none;
  padding: 0;
}
.book-slider {
  .owl-carousel {
    button {
      cursor: pointer;
      outline: none;
      border: none;
      background: none;
      margin: 0;
      padding: 0;
    }

    .owl-height {
      height: 240px !important;
    }
    .owl-stage {
      padding-left: 140px !important;
      @media (max-width: 1024px) {
        padding-left: 35px !important;
      }
    }
    .owl-next,
    .owl-prev {
      display: none;
    }
  }
  .sliderBook {
    padding: 16px;
    width: 176px !important;
    height: 227px !important;
    text-align: center;
    box-sizing: border-box;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;

    .book-placeholder,
    img.book-img {
      height: 224px;
      left: 0px;
      position: absolute;
      top: 10px;
      transition: transform 0.2s, border-color 0.2s;
      width: 176px;
    }

    .book-placeholder {
      p {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8px;
      }
    }

    .bottom-right-button,
    .bottom-left-button {
      > .icon-wrap {
        border-radius: 50%;
        height: 40px;
        overflow: hidden;
        position: absolute;
        top: 186px;
        width: 40px;
      }
    }

    .bottom-right-button {
      > .icon-wrap {
        left: 125px;
      }
      .icon-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        box-shadow: none;
        transition: transform 0s, border-color 0s;
      }
    }

    .bottom-left-button {
      > .icon-wrap {
        left: 10px;
        &::after {
          content: "";
          height: 40px;
          left: 0;
          position: absolute;
          top: 0;
          width: 40px;
        }
      }
      .icon-img {
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: transparent;
        box-shadow: none;
        transition: transform 0s, border-color 0s;
        position: relative;
        z-index: 20;
      }
    }

    .top-right-button {
      .icon-img {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 125px;
        top: 18px;
        border-radius: 50%;
        padding: 5px;
        background: #bb26d9;
        border: none;
        box-shadow: none;
        transition: transform 0s, border-color 0s;
      }
    }

    .bottom-right-button,
    .bottom-left-button,
    .top-right-button {
      visibility: hidden;
    }

    &:hover {
      .bottom-right-button,
      .bottom-left-button,
      .top-right-button {
        visibility: visible;
      }
    }

    &:hover {
      img.book-img {
        transform: scale(1.05, 1.05);
        border-color: #ffffff;
      }
      .reading-level {
        visibility: hidden;
      }
    }

    .reading-level {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px;
      gap: 8px;
      position: absolute;
      width: 168px;
      height: 26px;
      left: 4px;
      top: 204px;
      background: #0000008a;
      background-blend-mode: multiply;
      border-radius: 4px;

      .reading-text {
        width: 100px;
        height: 18px;
        text-align: center;
        margin: auto;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
      }
    }
  }
}
