@import "../../sass/variables.scss";

// *::-webkit-scrollbar {
//   display: none !important;
// }

* {
  -ms-overflow-style: none !important;
}

// * {
//   scrollbar-width: none !important;
// }
.button-text-decoration {
  text-decoration: none !important;
  color: black !important;
  text-align: left !important;
}

.viewer-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
}

.book-2 {
  margin: 18px !important;
}
.hide-for-now {
  visibility: none;
}
.page-header {
  position: relative;
  background-color: $toolbarBg;
  margin: 0;
  padding: 0;
  height: 55px;
  width: 57.4%;
  z-index: 100;
  top: 0px;
  .img {
    padding-right: 2px;
  }
  @media (max-width: 1060px) {
    width: 61.2%;
  }
  .dropdown-img-black {
    display: none;
  }

  .show {
    input:focus {
      outline: none !important;
      box-shadow: none !important;
      -moz-box-shadow: none !important;
      -webkit-box-shadow: none !important;
      border-color: #808080 !important;
    }
    .dropdown-toggle::after {
      display: inline-block !important;
      margin-left: 0.255em !important;
      vertical-align: 0 !important;
      content: "\f00d";
      border-top: 0 !important;
      border-right: 0.3em solid transparent !important;
      border-bottom: 0;
      border-left: 0.3em solid transparent !important;
      font-family: "Font Awesome 5 Free";
      font-size: 13px !important;
      font-weight: 900;
      color: black !important;
    }
    .dropdown-text {
      color: black !important;
    }
    .dropdown-img {
      display: none;
    }
    .dropdown-img-black {
      display: inline;
    }
  }

  .dropdown-toggle::after {
    display: inline-block !important;
    margin-left: 0.255em !important;
    vertical-align: 0 !important;
    content: "\f078";
    border-top: 0 !important;
    border-right: 0.3em solid transparent !important;
    border-bottom: 0;
    border-left: 0.3em solid transparent !important;
    font-family: "Font Awesome 5 Free";
    font-size: 13px !important;
    font-weight: 900;
    color: #808080;
  }

  .item,
  .item.btn.btn-default {
    border: none;
    padding: 8.5px 17px;
    font-weight: 100;
    margin-right: 15px;
    width: wrap;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
    border-radius: 100px;
    @media (max-width: 1060px) {
      font-size: 13px;
      padding: 8.5px 10px;
    }
  }
  .round-button {
    padding: 0 !important;
    width: 40px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
    border-radius: 500px;
    border: none;
    margin-right: 10px !important;
    img {
      padding-right: 0px !important;
    }
  }
  .book-blm-menu {
    margin-right: 10px !important;
  }
  .btn-group .item,
  .btn-group .item.btn.btn-default {
    margin-right: 0px !important;
  }
  .closing-button {
    padding-right: 0px;
    .item.btn.btn-default {
      padding: 11px 17px;
      margin-right: 20px;
    }
  }
  button.item {
    user-select: none; //hopefully this fixes the issue of being able to highlight the text in the toolbar
  }
  ul.dropdown-menu.blm {
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    left: 150px;
    max-height: 455px;
    min-height: 455px;
    min-width: 240px;
    max-width: 240px;
  }
  ul.dropdown-menu {
    position: fixed !important;
    border-radius: 16px !important;
    background: #ffffff !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12) !important;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 455px;
    min-height: 455px;
    min-width: 240px;
    max-width: 240px;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  .caret {
    margin-right: 0px;
    @media (max-width: 1060px) {
      margin-right: -2px;
    }
  }
  p,
  img {
    padding-right: 10px;
    display: inline-block;
    margin: 0;
  }
}
.toggle {
  position: absolute;
  top: -25px;
  margin: 0 50%;
  left: -20px;
  z-index: 9;
  .btn {
    background-color: $yellow;
    color: white;
    font-size: larger;
    border: none;
    height: 40px;
    width: 40px;
    padding: 5px;
    border-radius: 40%;
    i {
      top: 12px;
      position: relative;
      margin-right: 0px;
    }
  }
}

.menu {
  padding-top: 25px;
  font-size: larger;
  .name {
    text-align: left;
  }
  i {
    float: right;
  }
}
button {
  cursor: pointer;
}
button.zoom-btn {
  background-color: transparent !important;
  border: none;
  padding: 10px 5px !important;
  padding-left: 10px !important;
}

.drawer-wrapper {
  align-items: center;
  background-color: #ffff;
  border-radius: 100px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: auto;
  margin-top: 8px;
  max-width: 275px;
  padding: 0px 10px;
  position: relative;
  width: auto;

  &,
  .zoom-btn-container {
    height: 40px;
  }
}

.zoom-btn-container {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;

  @media (max-width: 1060px) {
    padding: 0px 0px;
  }

  button.btn {
    border: none !important;
    box-shadow: none !important;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
}

.zoom-btn:nth-child(3)::after {
  content: "";
  border-left: 1px solid rgb(179, 179, 179);
  height: 30px;
  margin: 0 12px;
  margin-left: 12px;
}

.navbar {
  margin: 0;
  z-index: 1;
  border-radius: 0;
  background-color: white;
  height: auto;
  max-height: 13rem;
  padding-left: 20px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  display: block;

  &[data-collapse="true"] {
    height: 6rem;
  }

  img.navbar-brand {
    // width: 220px;
    height: 60px;
    padding: 5px;
    position: absolute;
    left: 35px;
  }
}
