@import "../../sass/variables.scss";
.page-btn,
.toolbar-page-btn {
  outline: none !important;
  box-shadow: none !important;
  .centered-icon {
    font-size: 2rem;
  }
}
.page-btn:focus,
.toolbar-page-btn:focus {
  box-shadow: 0px 0px 0px 2px rgba(252, 184, 35, 0.5) !important;
}

.hl-yellow:hover,
.hl-teal:hover,
.hl-orange:hover,
.hl-green:hover,
.hl-yellow:focus,
.hl-teal:focus,
.hl-orange:focus,
.hl-green:focus {
  outline: none;
  box-shadow: none;
}

.hl-yellow:hover,
.hl-yellow:focus {
  background-color: #ffea01;
  outline: 2px solid #fcb823;
  box-shadow: 0 0 3px #fcb823;
}

.hl-teal:hover,
.hl-teal:focus {
  background-color: #09d8f8;
  outline: 2px solid #fcb823;
  box-shadow: 0 0 3px #fcb823;
}

.hl-orange:hover,
.hl-orange:focus {
  background-color: #ff8f02;
  outline: 2px solid #fcb823;
  box-shadow: 0 0 3px #fcb823;
}

.hl-green:hover,
.hl-green:focus {
  background-color: #63e60d;
  outline: 2px solid #fcb823;
  box-shadow: 0 0 3px #fcb823;
}

.toolbar {
  background-color: $toolbarBg;
  border-top-width: 2px;
  bottom: 0px;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-height: 80px;
  padding: 24px 0 16px;
  position: fixed;
  width: 75%;

  &,
  .iconTools,
  .pages-btn-container {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .iconTools {
    justify-content: flex-start;
  }
  .pages-btn-container {
    justify-content: flex-end;
  }
  .center-tools {
    width: 23%;
  }
  .right-tools {
    width: 35%;
  }
  .item {
    user-select: none; //hopefully this fixes the issue of being able to highlight the text in the toolbar
  }
  &.row {
    margin-left: 0px;
    margin-right: 0px;
    z-index: 3;
  }
  .iconTools button.active.btn {
    border-bottom-color: $blue;
  }
  .hl-highlight::after {
    display: none;
  }
  .btn.btn-primary::after {
    display: none;
  }
  .dropdown-item {
    width: 30%;
    min-height: 40px;
    margin: 4px 0px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    padding: 30px;
  }
  .item {
    margin: 4px;
    display: inline-block;
    &.btn-light {
      padding: 8px 8px 11px 8px;
      width: 56px;
      height: 59px;
      i {
        font-size: 40px;
      }
    }
    .item-child {
      padding: 8px;
      width: 56px;
      height: 56px;
    }
  }
  .item-text {
    color: white;
  }
  .btn-zoom {
    .fa {
      font-size: 36px;
    }
  }
  .text-blue {
    color: $blue;
  }
  .toolbar-menu {
    margin: 0px -10px !important;
    min-width: 56px;
    padding: 3px;
    width: 56px;
    border: none;
    background-color: transparent;
    bottom: 98%;
    li {
      width: 40px;
      margin: 8px auto;
      border-radius: 4px;
    }
    li > a {
      padding: 3px;
      width: 40px;
      height: 40px;
      margin: 8px auto;
      border: 0.5px solid white;
      border-radius: 4px;
    }
  }
  .item.last-page,
  .item.first-page {
    display: none;
  }
}
button.page-btn {
  border: 1px solid #b3b3b3 !important;
  background-color: #f5f5f5 !important;
  margin-right: -4px !important;
  margin-left: 10px !important;
  width: 48px;
  height: 48px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 0px !important;
}
button.page-btn:focus {
  outline-color: #fcb823 !important;
}
.toolbar-page-btn {
  border: 1px solid #b3b3b3 !important;
  background-color: #f5f5f5 !important;
  width: 48px;
  height: 48px;
  background: #f5f5f5;
  border-radius: 8px;
  margin-left: 10px;
  padding: 0px !important;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .toolbar {
    .center-tools {
      width: 17%;
    }
    .right-tools {
      width: 40%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .toolbar .item.last-page,
  .toolbar .item.first-page {
    display: inline-block;
  }
}
