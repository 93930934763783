@import "~bootstrap/scss/bootstrap";
@import "sass/loader.scss";
@import "sass/variables.scss";
@import "sass/basics.scss";
@import "sass/responsive.scss";
@import "components/bookview/BookView.scss";
@import "components/header/Header.scss";
@import "components/bookview/Pages.scss";
@import "components/bookview/Toolbar.scss";
@import "components/bookview/Drawer.scss";
@import "components/bookbag/BookBag.scss";
@import "components/auth/Login.scss";
@import "components/auth/Register.scss";
@import "components/common/Loading.scss";
@import "components/blm/BLM.scss";
@import "vendor/selectize.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
