@import "../../sass/variables.scss";
@import "./Slider.scss";


.App .btn.close-book {
  position: fixed;
  right: 10px;
  top: 10px;
  padding: 0px;
  z-index: 110;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  border-radius: 500px;
  border: none;
  width: 40px;
  height: 40px;
}

.page-slider {
  margin: 4px 16px 0 !important;
  width: 75%;
}
.pages-visible-buttons {
  position: fixed;
  top: 80px;
  right: 0;

  z-index: 10;
  .btn {
    display: block;
    margin-top: 10px;
    border-bottom-right-radius: 0;
  }
}
.audio-highlight-text {
  background-color: rgb(198, 230, 247);
  span {
    background-color: rgb(198, 230, 247);
  }
}

.bubble-text {
  margin: 0px 3px;
  background-color: #808080;
  color: white;
  border-radius: 100px;
  padding: 6px 12px;
  width: 20px;
  height: 11px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  leading-trim: both;
  text-edge: cap;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

#readium-id,
.pages {
  --offset: 0px;
  position: relative;
  .pageIndex {
    position: absolute; // z-index: 100;
    top: var(--nav-size);
    @media (max-width: 1024px) {
      top: 21% !important;
    }
    &.prev {
      left: -46px;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
      .btn {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        background: #ffffff;
        border-radius: 0px 100px 100px 0px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
      }
    }
    &.next {
      opacity: 0.6;
      z-index: 100;
      &:hover {
        opacity: 1;
      }
      .btn {
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        background: #ffffff;
        border-radius: 0px 100px 100px 0px;
        margin-left: 2px;
        img {
          left: 8px;
          top: 160px;
          @media (max-width: 1024px) {
            top: 110px;
          }
        }
      }
    }
    .btn {
      padding-left: 3px;
      padding-right: 3px;
      padding-top: 0px;
      max-height: 400px;
      height: 40vh;
      width: 46px;
      vertical-align: middle;
      border: none;
      color: white;
      img {
        width: 30px;
        height: 56px;
      }
      &:hover {
        background-color: #f2f2f2;
      }
      &:focus {
        background-color: #f3f3f3;
      }
      &:active {
        background-color: $darkGray;
        img {
          color: #ffffff;
        }
      }

      @media (max-width: 1024px) {
        height: 270px !important;
      }
    }
  }

  &[data-type="standard"] {
    --offset: 0px;
  }

  &[data-type="wide"] {
    --offset: 14px;
  }
}

#readium-id {
  .pageIndex {
    --extraSpace: calc(100vw - var(--book-width));
    --halvedSpace: calc(var(--extraSpace) / 2);

    &.prev {
      left: calc(var(--halvedSpace) - 43px);
    }
    &.next {
      margin-left: calc(var(--halvedSpace) + var(--book-width));
    }
  }
}

.pages {
  user-select: text;
  opacity: 0;
  box-shadow: 0px 1.8568079471588135px 3.623039960861206px rgba(0, 0, 0, 0.02),
    0px 5.133836269378662px 10.017241477966309px rgba(0, 0, 0, 0.04),
    0px 12.360300064086914px 24.117658615112305px rgba(0, 0, 0, 0.05),
    0px 41px 80px rgba(0, 0, 0, 0.07);
  &.note-mode {
    cursor: url("../../images/note_cursor.jpg"), auto;
  }
  &.hl-mode {
    cursor: url("../../images/icon_hl_yellow_cursor.png") 2 20, auto;
  }
  &.eraser-mode {
    cursor: url("../../images/icon_eraser_cursor.png") 2 20, auto;
  }
  &.hl-mode-hl-yellow {
    cursor: url("../../images/icon_hl_yellow_cursor.png") 2 20, auto;
    ::selection {
      background-color: $hlyellow;
    }
  }
  &.hl-mode-hl-green {
    cursor: url("../../images/icon_hl_green_cursor.png") 2 20, auto;
    ::selection {
      background-color: $hlgreen;
    }
  }
  &.hl-mode-hl-teal {
    cursor: url("../../images/icon_hl_teal_cursor.png") 2 20, auto;
    ::selection {
      background-color: $hlteal;
    }
  }
  &.hl-mode-hl-orange {
    cursor: url("../../images/icon_hl_orange_cursor.png") 2 20, auto;
    ::selection {
      background-color: $hlorange;
    }
  }
  &.move-mode {
    cursor: url("../../images/move_icon_cursor.png") 2 20, auto;
  }
  .pageContainer {
    // overflow: hidden;
    // position: absolute; // max-height: 90vh;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // transform: translateZ(0px);
  }
  .page {
    // width: 49.8%;
    // height: 100%;
    border: 1px solid black !important;
    background-color: white;
    box-shadow: none !important;
    box-sizing: content-box !important;
    opacity: 1; // transition: all 0.5s ease;
    &.invisible-left {
      opacity: 0.1;
      position: absolute;
      visibility: hidden;
      // left: -2000%; // transition: all 0.5s ease;
    }
    &.invisible-right {
      opacity: 0.1;
      position: absolute;
      visibility: hidden;
      // left: 2000%;
    }
    &.single-page {
      width: 99.6%;
      float: left;
      position: absolute;
    }
    &.blm-mode {
      width: 49.8%;
    }
    &.blm-page {
      // width: 44%;
      // right: 0;
      position: absolute;
      // background-color: transparent;
      // canvas {
      //     width: 100% !important;
      //     height: 100% !important;
      // }
    }
  }
  .note {
    opacity: 0.3;
    &.active {
      opacity: 1;
    }
  }
  .note:hover {
    opacity: 1;
  }
  img {
    width: 30px;
  }
}

.note-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: 280px;
  height: 256px;
  left: 184px;
  top: 245px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  border-radius: 16px 16px 24px 24px;
  z-index: 100;
  .note-text-area {
    &:focus {
      outline: none;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    width: 280px;
    height: 200px;
    background: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    .type-note {
      width: 105px;
      height: 21px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #b3b3b3;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
  .teacher {
    background: rgba(187, 38, 217, 0.15) !important;
  }
  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: 280px;
    height: 56px;
    background: rgba(252, 184, 35, 0.15);
    border-radius: 0px 0px 24px 24px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    .delete {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 9px 8px 10px;
      width: 62px;
      height: 40px;
      border-radius: 500px;
      flex: none;
      order: 0;
      flex-grow: 0;
      cursor: pointer;
      .button-label {
        width: 46px;
        height: 21px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        color: #bf4040;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }
    .right-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .cancel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 9px 8px 10px;
        background-color: inherit;
        width: 68px;
        height: 40px;
        border-radius: 500px;
        flex: none;
        order: 0;
        flex-grow: 0;
        cursor: pointer;
        .button-label {
          width: 52px;
          height: 21px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          color: #808080;
          flex: none;
          order: 0;
          flex-grow: 0;
        }
      }
      .save {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 9px 16px 10px;
        width: 68px;
        height: 40px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(204, 204, 204, 0.5) 100%
          ),
          #3db8f5;
        background-blend-mode: multiply, normal;
        border-radius: 500px;
        flex: none;
        order: 1;
        flex-grow: 0;
        cursor: pointer;
        .button-label {
          width: 36px;
          height: 21px;

          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          color: #ffffff;
          flex: none;
          order: 0;
          flex-grow: 0;
        }
      }
    }
  }
}

.teacher-student-popover-note {
  background-color: $teacherStudentNotes;
  .popover-title {
    background-color: $teacherStudentNotes;
  }
  .arrow:after {
    border-top-color: $teacherStudentNotes !important;
    border-bottom-color: $teacherStudentNotes !important;
  }
}
.teacher-group-popover-note {
  background-color: $groupNotes;
  .popover-title {
    background-color: $groupNotes;
  }
  .arrow:after {
    border-top-color: $groupNotes !important;
    border-bottom-color: $groupNotes !important;
  }
}
.teacher-class-popover-note {
  background-color: $classNotes;
  .popover-title {
    background-color: $classNotes;
  }
  .arrow:after {
    border-top-color: $classNotes !important;
    border-bottom-color: $classNotes !important;
  }
}
.student-popover-note {
  background-color: $studentNotes;
  .popover-title {
    background-color: $studentNotes;
  }
  .arrow:after {
    border-top-color: $studentNotes !important;
    border-bottom-color: $studentNotes !important;
  }
}
.popover.bottom {
  .arrow:after {
    top: 1.5px;
  }
}

.popover-content {
  // height: 120px;
  width: 200px;
}

.btn-popover {
  padding: 1px 8px 1px 8px;
  margin: 2px;
  // background-color: #A43A37;
  // color: #fff;
}

textarea.popover-textarea {
  border: 0px;
  border-radius: 5px;
  margin: 0px;
  width: 100%;
  height: 100%;
  padding: 4px;
  box-shadow: none;
  color: black;
  font-size: 14px;
}

textarea.form-control {
  resize: none;
  border: none;
  border-radius: 16px 16px 0px 0px;
  padding: 30px;
}

.range-slider {
  width: 88% !important;
  background-color: $lightGray;
  @media (max-width: 1024px) {
    width: 70% !important;
  }
}

.current-page-info {
  font-size: 1vw;
  color: #b3b3b3;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  white-space: nowrap;
  @media (max-width: 1024px) {
    font-size: 2vw;
  }
}
input[type="range"] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #ccc; // updated color
    border-radius: 1px;
    border: 0px solid #000000;
  }

  &::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #d3d3d3;
    height: 15px;
    width: 15px;
    border-radius: 25px;
    background: #808080;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }

  &:focus::-webkit-slider-runnable-track {
    background: #d3d3d3;
  }

  &::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #ccc; // updated color
    border-radius: 1px;
    border: 0px solid #000000;
  }

  &::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #d3d3d3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #b3b3b3;
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    //background: #ccc; // updated color
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }

  &::-ms-fill-upper {
    background: #ccc; // updated color
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }

  &::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #d3d3d3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #b3b3b3;
    cursor: pointer;
  }

  &:focus::-ms-fill-lower {
    background: #d3d3d3;
  }

  &:focus::-ms-fill-upper {
    background: #d3d3d3;
  }
}

.pages-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  // header 62px + footer 80px
  height: calc(100vh - 142px);

  @media (max-height: 900px) {
    align-items: center;
  }
}

.pages-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0;
  width: 100%;

  &[data-big="true"] {
    overflow: auto;

    #pages {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .close-book {
    i {
      font-size: 20px;
      position: absolute;
      right: 10px;
    }
  }
}
