@import "../../sass/variables.scss";

#bookViewPage {
  height: 100%;
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
    height: 100%;
    min-height: 100%;
  }
  .popover-delete,
  .popover-submit,
  .popover-cancel {
    .hide {
      visibility: none;
    }
  }
  #readium-id {
    padding-top: 30px;
  }
}
